<template>
  <div class="upload">
    <div>
      <h1>Welcome to the upload page!</h1>
      <h2>You can upload your IFC files below:</h2>
    </div>
    <UploadIfc />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import UploadIfc from '../components/UploadIfc.vue';

export default defineComponent({
  name: 'UploadPage',
  components: {
    UploadIfc,
  },
});
</script>
