<template>
  <div class="container mx-auto px-64">
    <file-pond
      allow-multiple="false"
      file-validate-type-label-expected-types="Expects an IFC file"
      accepted-file-types="application/x-step"
      :file-validate-type-detect-type="fileValidateTypeDetectType"
      instant-upload="false"
      :server="server"
      :label-file-processing-error="labelFileProcessingError"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

const referencePoints = { refX: 8.49, refY: 18.91, refLat: 51.34128928076572, refLong: 12.391055238108324 };

let serverResponse;

export default defineComponent({
  name: 'UploadIfc',
  components: {
    FilePond,
  },
  methods: {
    fileValidateTypeDetectType: (source, type) =>
      new Promise((resolve, reject) => {
        const splittedName = source.name.split('.');
        const extension = splittedName[splittedName.length - 1];
        if (extension === 'ifc') {
          resolve('application/x-step');
        } else {
          reject(type);
        }
      }),
    labelFileProcessingError: () => {
      return serverResponse.message;
    },
  },
  data: function () {
    return {
      server: {
        process: {
          url: `${
            process.env.NODE_ENV === 'production' ? 'http://45.129.182.38:3335' : 'http://localhost:3335'
          }/api/ifc`,
          onerror: (response) => {
            serverResponse = JSON.parse(response);
          },
          ondata: (formData) => {
            formData.append('refX', referencePoints.refX);
            formData.append('refY', referencePoints.refY);
            formData.append('refLat', referencePoints.refLat);
            formData.append('refLong', referencePoints.refLong);
            return formData;
          },
        },
      },
    };
  },
});
</script>
